<template>
  <div class="goodsListCard">
    <el-tabs type="border-card" >
      <!-- <el-tab-pane :label="item"  > -->
        <div class="tabs-wrap">
          <div class="items" :class="index === curIndex ? 'active':''" 
            v-for="(item, index) in typeList" :key="index" @click="indexClick(item,index)">
            {{item}}</div>
        </div>
        
        <div class="u-f-ac">
            <el-button size="small" @click="clickUpdate('上架中')">上架</el-button>
            <el-button size="small" @click="clickUpdate('已下架')">下架</el-button>
            <el-button size="small" @click="chooseDel">删除</el-button>
        </div>

        <!-- 表格 -->
        <el-table
          ref="multipleTable"
          :data="borderList"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :row-key="getRowKeyOfTarget">
          <el-table-column type="selection" reserve-selection width="55"></el-table-column>
          <el-table-column prop="goodsNo" label="商品编号"></el-table-column>
          <el-table-column prop="goodsName" label="商品名称"></el-table-column>
          <el-table-column label="商品图片" width="120">
            <template slot-scope="scope">
              <img :src="scope.row.masterImageUrl" alt="" width="60" height="40" />
            </template>
          </el-table-column>
          <el-table-column prop="price" label="价格(元)" sortable></el-table-column>
          <el-table-column label="库存">
            <template slot-scope="scope">
              <div>{{scope.row.inventory}} {{scope.row.units}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="collen" label="收藏" sortable></el-table-column>
          <el-table-column prop="status" label="状态"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handeClickEdit(scope.row)">编辑</el-button>
              <el-button @click="handleClickLook(scope.row)" type="text" size="small">查看</el-button>
              <el-button @click="handleClickDel(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page-wrap">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currpage"
            :page-sizes="[5, 10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo"
          >
          </el-pagination>
        </div>
      <!-- </el-tab-pane> -->
    </el-tabs>
    <!-- 查看 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%">
      <div class="dialo-wrap">
        <previewPage ref="previewPage"></previewPage>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import previewPage from "./previewPage";
export default {
  components:{
    previewPage
  },
  data() {
    return {
      borderList: [],
      typeList: ["全部","上架中","已下架","仓库中"],
      currpage: 1,
      pagesize: 5,
      pageInfo: 0,
      idList: [],
      dialogVisible: false,
      curIndex: 0,
      keyword: null,
      startDate: null,
      endDate: null,
      minPrice: null,
      maxPrice: null,
      catalogId: null
    };
  },
  mounted(){
    this.getGoodsListbyBrand()
  },
  methods: {
    setValue(value){
      let val = value.catalogId;
      this.keyword = value.goodsName;
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.minPrice = value.minPrice;
      this.maxPrice = value.maxPrice;
      this.catalogId = val[val.length-1]
      this.getGoodsListbyBrand()
    },
    //修改商品状态
    clickUpdate(status){
      let params = {
           ids: this.idList,
           status: status
      }
      this.$API.updateGoodsCatalogStatus(params).then( res => {
          if(res.code === 0){
              this.$message.success("修改商品状态成功");
              this.getGoodsListbyBrand();
          }
      })
    },
    // 点击切换不同状态的商品列表
    indexClick(item,index){
      this.curIndex = index
      switch (index) {
        case 0:
          this.getGoodsListbyBrand()
          break;
        case 1:
          this.getGoodsListbyBrand(item)
          break;
        case 2:
          this.getGoodsListbyBrand(item)
          break;
        case 3:
          this.getGoodsListbyBrand(item)
          break;
        default:
          break;
      }
    },
    // 查看
    handleClickLook(row){
      this.dialogVisible = true;
      let params = {
          id: row.id,
      }
      this.$API.getGoodsInfoPost(params).then( res => {
          if(res.code === 0){
            let value = res.data;
            let text = value.specificationDetailsObj.copyContent;
            var result = text.map(o=>{
                return{
                    value: o, 
                }
            });
            let baseValue = {
              imageUrlList: value.imageUrlList,
              goodsName: value.goodsName,
              description: value.description,
              price: value.price
            }

            let detailValue = {
              goodsDetail: value.productDetailImgList,
              mountingsList: value.accessoriesImgList,
              textForm: {
                contentTextList: result,
              },
              tableForm: {
                contentTableList: value.specificationDetailsObj.productPerfomances,
              },
              drawingFrom: value.productDrawingList
            }
            this.$nextTick(()=>{
                this.$refs.previewPage.setValue(baseValue);
                this.$refs.previewPage.setDetail(detailValue)
            })
          }
      })
    },
    //选中删除
    chooseDel(){
      if(this.idList.length !=0 ){
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            let params = {
                ids: this.idList,
            }
            this.$API.deleteGoodsPost(params).then( res => {
                if(res.code === 0){
                    this.getGoodsListbyBrand();
                    this.$message.success("删除分类成功！");
                }
            })
        }).catch(() => {
        this.$message({
            type: 'info',
            message: '已取消删除'
        });          
        });
      }else{
        this.$message.error("请选择需要删除的商品")
      }
    },
    //删除
    handleClickDel(row){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          let ids = [];
          ids.push(row.id)
          let params = {
              ids: ids,
          }
          this.$API.deleteGoodsPost(params).then( res => {
              if(res.code === 0){
                  this.getGoodsListbyBrand();
                  this.$message.success("删除分类成功！");
              }
          })
      }).catch(() => {
      this.$message({
          type: 'info',
          message: '已取消删除'
      });          
      });
      
    },
    //点击编辑获取商品信息
    handeClickEdit(row){
      this.$router.push({ name: "goodsReleased", query: { id: row.id } });
    },
    //获取商品列表
    getGoodsListbyBrand(status){
      let params = {
        keyword: this.keyword,
        currentPage: this.currpage,
        pageSize: this.pagesize,
        status: status,
        startDate: this.startDate,
        endDate: this.endDate,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        catalogId: this.catalogId
      }
      this.$API.getGoodsByBrandList(params).then( res => {
        if(res.code === 0){
          this.borderList = res.data.list
          this.pageInfo = res.data.pageInfo.totalRow
        }
      })
    },
    getRowKeyOfTarget(row) {
      return row.id;
    },
    handleCurrentChange(cpage) {
      this.currpage = cpage;
      this.getGoodsListbyBrand();
    },
    handleSizeChange(psize) {
      this.pagesize = psize;
      this.getGoodsListbyBrand();
    },
    //全选
    handleSelectionChange(val) {
      let idsList = [];
      val.forEach(v => {
        idsList.push(v.id)
      });
      this.idList = idsList;
    },
  },
};
</script>
<style lang="scss" >
.goodsListCard {
    margin-top: 10px;
    .u-f-ac{
        display: flex;
        flex-flow: wrap;
        align-items: center;
        .bt {
            padding: 0 20px;
            color: rgb(63, 62, 62);
            background-color: rgb(255, 255, 255);
            height: 32px;
            border-radius: 4px;
            font-size: 12px;
            margin: 0 8px;
            border: 1px solid rgb(182, 181, 181);
        }
        .bt:hover {
            background-color: rgb(248, 246, 246);
        }
        .bt:active {
            background-color: rgb(236, 233, 233);
        }
    }
  .page-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  .dialo-wrap{
    height: calc(100vh - 160px);
    overflow: auto;
  }
  .tabs-wrap{
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 100%;
    background: #eee;
    margin-bottom: 10px;
    .items{
      padding: 10px 20px;
      font-size: 15px;
      color: #313233;
    }
    .active{
      background: #fff;
      border: 1px solid rgb(202, 200, 200);
      border-bottom: none;
    }
  }
}
</style>