<template>
  <div class="screenFromCard">
    <el-form ref="screenFromRef" :model="screenFrom" label-width="80px">
      <div class="u-f-ac">
        <el-form-item label="创建时间" prop="createTime" style="margin-left: 16px">
          <el-date-picker
            size="small"
            v-model="screenFrom.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <div class="u-f-ac">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            class="goodiput"
            size="small"
            placeholder="请输入商品/配件名称"
            clearable
            v-model="screenFrom.goodsName"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品类目" prop="catalogId">
          <el-cascader
            filterable
            style="width: 350px"
            :options="classOptions"
            v-model="screenFrom.catalogId"
            :props="{ checkStrictly: true }"
            clearable
            size="small"
          ></el-cascader>
        </el-form-item>
      </div>
      <div class="u-f-ac">
        <el-form-item label="收藏数" prop="collectNum1">
          <el-input
            class="collect-price"
            size="small"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="screenFrom.collectNum1"
          ></el-input>
        </el-form-item>
        <div class="line"></div>
        <el-form-item style="margin-left: -80px" prop="collectNum2">
          <el-input
            class="collect-price"
            size="small"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="screenFrom.collectNum2"
          ></el-input>
        </el-form-item>

        <el-form-item style="margin-left: 16px" label="价格" prop="minPrice">
          <div class="u-f-ac">
            <el-input
              class="collect-price"
              size="small"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              v-model="screenFrom.minPrice"
            ></el-input>
            <div class="yuan">元</div>
          </div>
        </el-form-item>
        <div class="line"></div>
        <el-form-item style="margin-left: -80px" prop="maxPrice">
          <div class="u-f-ac">
            <el-input
              class="collect-price"
              size="small"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              v-model="screenFrom.maxPrice"
            ></el-input>
            <div class="yuan">元</div>
          </div>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" size="small" style="width: 80px" @click="sreenFrom">筛选</el-button>
        <el-button type="info" size="small" style="width: 80px" @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {dateToMs} from "@/methods/util";
export default {
  data() {
    return {
      screenFrom: {
        createTime: '',
        startDate: '',
        endDate: '',
        goodsName: "",
        catalogId: [],
        collectNum1: "",
        collectNum2: "",
        minPrice: "",
        maxPrice: "",
      },
      classOptions: [],
    };
  },
  mounted(){
    this.getListCatalogType();
  },
  methods: {
    resetForm() {
      this.$refs.screenFromRef.resetFields();
      this.screenFrom.startTime = "";
      this.screenFrom.endTime = "";
    },
    // 获取商品类目结构
    getListCatalogType(){
      this.$API.getListCatalogType().then( res => {
          if(res.code === 0){
              let resultSecond;
              var result = res.data.map(o=>{
                  if(o.childrenNode){
                      resultSecond =  o.childrenNode.map(c=>{
                          return{
                              value: c.data.id, 
                              label: c.data.catalogName,
                          }
                      })
                  }
                  return{
                      value: o.data.id, 
                      label: o.data.catalogName,
                      children: resultSecond
                  }
              });
              this.classOptions = result;
          }
      })
    },
    sreenFrom() {
      if(this.screenFrom.createTime){
        this.screenFrom.startDate = dateToMs(this.screenFrom.createTime[0]);
        this.screenFrom.endDate = dateToMs(this.screenFrom.createTime[1]);
      }
      this.$emit('screenData',this.screenFrom)
    },
  },
};
</script>
<style lang="scss" >
.screenFromCard {
  margin-top: 10px;
  background-color: #f2f3f5;
  padding: 10px 0;
  .u-f-ac{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .goodiput {
    width: 212px;
  }
  .collect-price {
    width: 98px;
  }
  .line {
    margin: 0 4px;
    margin-bottom: 20px;
    height: 1px;
    width: 8px;
    background-color: rgb(170, 141, 141);
  }
  .yuan {
    width: 20px;
    height: 31px;
    text-align: center;
    line-height: 31px;
    border-radius: 4px;
    margin-left: -25px;
    padding: 0 2px;
    font-size: 12px;
    color: rgb(150, 150, 150);
    background-color: rgb(236, 232, 232);
    z-index: 200;
  }
}
</style>